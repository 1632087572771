import {
  GridForm,
  TrackingContextProvider,
  WordmarkLogo,
  TrackedLink,
  FLOCK_EMAIL,
  GenericStepComponentProps,
  AnyInputConfig,
} from '@flock/shared-ui'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { HOMEPAGE_PATH } from '../../routeConstants'
import { LoadingBackgroundProps } from './BackgroundComponents/LoadingBackground'
import {
  OnboardingBackgroundProps,
  StaticOnboardingBackgroundProps,
} from './BackgroundComponents/OnboardingBackground'
import { PropertyDetailBackgroundProps } from './BackgroundComponents/PropertyDetailBackground'
import { ProgressType } from './WebflowHeader'
import { WebflowData } from './webflowTypes'

type Functionify<U> = U extends any ? React.FC<U> : never

export type InjectedWebflowStepProps = {
  stepData: { [key: string]: any }
  flowData: Partial<WebflowData>
  headline?: string
  description?: string
  backgroundComponentProps?: {
    streetViewImageUrl?: string
    backgroundTransition?: boolean
  }
}

export type WebflowStepProps<
  BackgroundComponentProps extends
    | LoadingBackgroundProps
    | StaticOnboardingBackgroundProps
    | OnboardingBackgroundProps
    | PropertyDetailBackgroundProps
> = {
  headline?: string
  description?: string
  prefillDescription?: string
  stepName: string
  progress: ProgressType
  spacing?: number
  addButtonPadding?: boolean
  autoProgressTime?: number
  hideBack?: boolean

  inputConfigs: AnyInputConfig[]
  preprocessInputConfigs?: (
    inputConfigs: AnyInputConfig[],
    flowData: Partial<WebflowData>
  ) => AnyInputConfig[]
  backText?: string
  ctaText?: string

  backgroundComponent: Functionify<BackgroundComponentProps>
  backgroundComponentProps?: BackgroundComponentProps
}

const WebflowStep = (
  props: GenericStepComponentProps<WebflowData> &
    InjectedWebflowStepProps &
    WebflowStepProps<
      | LoadingBackgroundProps
      | StaticOnboardingBackgroundProps
      | OnboardingBackgroundProps
      | PropertyDetailBackgroundProps
    >
) => {
  const {
    onSubmit,
    onBack,
    headline,
    description,
    inputConfigs,
    stepName,
    stepData,
    backText,
    ctaText,
    autoProgressTime,
    loading,
    error,
    preprocessInputConfigs,
    spacing,
    addButtonPadding,
    flowData,
    hideBack,
    backgroundComponent: BackgroundComponent,
    backgroundComponentProps,
    prefillDescription,
  } = props

  const [currentFormState, setCurrentFormState] = useState<{
    [key: string]: any
  }>({})

  useEffect(() => {
    // On the last step, we have a timeout that calls on submit
    if (autoProgressTime) {
      setTimeout(() => onSubmit({}), autoProgressTime)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoProgressTime])

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  let processedInputConfigs = inputConfigs
  if (preprocessInputConfigs) {
    processedInputConfigs = preprocessInputConfigs(inputConfigs, flowData)
  }

  return (
    <TrackingContextProvider name={stepName}>
      <BackgroundComponent
        currentFormState={currentFormState}
        {...(backgroundComponentProps || {})}
      />
      <Box
        position="absolute"
        height="max(100%, 750px)"
        width={{
          xs: '100vw',
          md: '50vw',
        }}
        right="0px"
        zIndex={-1}
        sx={{
          backgroundColor: {
            xs: backgroundComponentProps?.hideTabletBackground
              ? 'trustBlue.main'
              : 'unset',
            md: 'trustBlue.main',
          },
        }}
      />
      <Box width="100%" height="100%" display="flex" justifyContent="center">
        <Box
          py={{ xs: '24px', sm: '88px', md: '88px' }}
          boxSizing="border-box"
          width="100%"
          height="100%"
        >
          <Box
            px={{ xs: '35px', sm: '100px', md: '160px' }}
            height="100%"
            maxWidth={{ xs: '304px', sm: '544px', md: '1120px' }}
            margin="auto"
          >
            <Grid container height="100%">
              {!isTablet && (
                <Grid
                  item
                  xs={5}
                  position="relative"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  gap="32px"
                  height="max(100%, 450px)"
                >
                  <TrackedLink
                    aria-label="Homepage"
                    sx={{
                      justifySelf: 'flex-start',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      '&:hover': {
                        backgroundColor: 'unset',
                      },
                    }}
                    onClick={() => navigate(HOMEPAGE_PATH)}
                  >
                    {stepName !== 'propertyCondition' && (
                      <WordmarkLogo width={97} height={28} />
                    )}
                  </TrackedLink>
                  <Box display="flex" flexDirection="column" gap="32px">
                    <Typography variant="h1" color="moneyGreen.main">
                      {headline}
                    </Typography>
                    <Typography variant="h4">
                      {prefillDescription &&
                      flowData?.prefillData?.prefillBedCount
                        ? prefillDescription
                        : description}
                    </Typography>
                  </Box>
                </Grid>
              )}
              {!isTablet && <Grid item xs={2} />}
              <Grid item xs={12} md={5} width="100%" height="100%">
                {!error ? (
                  <GridForm
                    formProps={{
                      defaultValues: stepData,
                    }}
                    gridProps={{ height: '100%', spacing }}
                    onSubmit={onSubmit}
                    onBack={onBack}
                    backText={backText}
                    ctaText={ctaText}
                    hideBack={!!autoProgressTime || hideBack}
                    hideSubmit={!!autoProgressTime}
                    headerComponent={
                      <Box minHeight={{ xs: '32px', sm: '60px' }} />
                    }
                    onUpdated={(updatedState) =>
                      setCurrentFormState(updatedState)
                    }
                    inputConfigs={processedInputConfigs}
                    loading={loading}
                    ctaBoxProps={{
                      sx: {
                        pb: addButtonPadding ? '16px' : '0px',
                      },
                    }}
                  />
                ) : (
                  <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gap="32px"
                  >
                    <Typography variant="h1" color="moneyGreen.main">
                      Oops, an error occurred.
                    </Typography>
                    <Typography variant="h4">
                      Please refresh the page and try again or contact{' '}
                      <TrackedLink
                        onClick={() => navigate(`mailto:${FLOCK_EMAIL}`)}
                        target="_blank"
                      >
                        info@flockhomes.com
                      </TrackedLink>{' '}
                      if the issue persists.
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </TrackingContextProvider>
  )
}

export default WebflowStep

WebflowStep.defaultProps = {
  spacing: 0,
  headline: '',
  description: '',
  loading: false,
  error: false,
  autoProgressTime: undefined,
  hideBack: false,
  preprocessInputConfigs: undefined,
  backText: '',
  ctaText: '',
  backgroundComponentProps: undefined,
  prefillDescription: undefined,
  addButtonPadding: false,
}
