import React from 'react'
import { Box, Typography } from '@mui/material'

export type ErrorDisplayProps = {
  text?: string
}

const DefaultMultiStepperError = (props: ErrorDisplayProps) => {
  const { text } = props
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="50vh"
    >
      <Typography variant="h3">{text}</Typography>
    </Box>
  )
}

DefaultMultiStepperError.defaultProps = {
  text: '',
}

export default DefaultMultiStepperError
