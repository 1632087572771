import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const HouseFilledIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M59.1564 31.5626L33.4127 5.8376C33.2273 5.65187 33.0072 5.50452 32.7648 5.40399C32.5224 5.30346 32.2626 5.25171 32.0002 5.25171C31.7378 5.25171 31.478 5.30346 31.2356 5.40399C30.9932 5.50452 30.7731 5.65187 30.5877 5.8376L4.84395 31.5626C4.09395 32.3126 3.66895 33.3313 3.66895 34.3938C3.66895 36.6001 5.4627 38.3938 7.66895 38.3938H10.3814V56.7501C10.3814 57.8563 11.2752 58.7501 12.3814 58.7501H28.0002V44.7501H35.0002V58.7501H51.6189C52.7252 58.7501 53.6189 57.8563 53.6189 56.7501V38.3938H56.3314C57.3939 38.3938 58.4127 37.9751 59.1627 37.2188C60.7189 35.6563 60.7189 33.1251 59.1564 31.5626Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default HouseFilledIcon
