import React from 'react'
import { Typography, Box, CircularProgress } from '@mui/material'

export type LoadingDisplayProps = {
  text?: string
}

const DefaultMultiStepperLoader = (props: LoadingDisplayProps) => {
  const { text } = props
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Typography variant="h3">{text}</Typography>
      <Box display="flex" justifyContent="center" paddingTop="48px">
        <CircularProgress />
      </Box>
    </Box>
  )
}

DefaultMultiStepperLoader.defaultProps = {
  text: '',
}

export default DefaultMultiStepperLoader
