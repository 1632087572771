import {
  flockTheme,
  LibraryThemeProvider,
  PageContainer,
} from '@flock/shared-ui'
import { ThemeProvider } from '@mui/material'
import React, { useEffect } from 'react'
import { useRecordPageDuration } from '../components/utils'
import WebflowForm from '../components/WebflowOnboardingComponents/WebflowForm'
import { webflowInputConfigs } from '../components/WebflowOnboardingComponents/webflowOnboardingInputsConfigs'
import '../styles/onboarding.css'

export const Head = () => (
  <>
    <script src="https://api.mapbox.com/mapbox.js/v3.3.1/mapbox.js" />
    <link
      href="https://api.mapbox.com/mapbox.js/v3.3.1/mapbox.css"
      rel="stylesheet"
    />
  </>
)

const Onboarding = () => {
  useEffect(() => {
    const isBrowser = typeof window !== 'undefined'

    const isWindow = isBrowser && window

    // Prompt before leaving page
    if (isWindow) {
      window.onbeforeunload = () => true
      return () => {
        window.onbeforeunload = null
      }
    }
    return () => {}
  }, [])
  useRecordPageDuration()

  return (
    <ThemeProvider theme={flockTheme}>
      <LibraryThemeProvider>
        <PageContainer title="Flock | Onboarding" trackingName="onboarding">
          <WebflowForm stepConfigs={webflowInputConfigs} />
        </PageContainer>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default Onboarding
