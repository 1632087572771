import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const WordmarkLogo = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined
  

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (<svg width={width || 272} height={height || 47} viewBox="0 0 272 47" fill="none">
<path d="M0 1.1371H28.0433V6.82258H6.04978V20.8468H26.5308V26.5323H6.04978V45.8629H0V1.1371Z" fill={fillStyle || 'black'}/>
<path d="M53.7258 1.1371H59.7756V40.1774H80.2566V45.8629H53.7258V1.1371Z" fill={fillStyle || 'black'}/>
<path d="M124.425 47C121.022 47 117.892 46.4104 115.035 45.2312C112.178 44.0099 109.72 42.3463 107.662 40.2406C105.645 38.1349 104.049 35.6501 102.872 32.7863C101.738 29.9225 101.171 26.8271 101.171 23.5C101.171 20.1729 101.738 17.0775 102.872 14.2137C104.049 11.3499 105.645 8.86514 107.662 6.75941C109.72 4.65367 112.178 3.0112 115.035 1.83199C117.892 0.610663 121.022 0 124.425 0C127.828 0 130.958 0.610663 133.815 1.83199C136.671 3.0112 139.108 4.65367 141.125 6.75941C143.183 8.86514 144.78 11.3499 145.914 14.2137C147.09 17.0775 147.679 20.1729 147.679 23.5C147.679 26.8271 147.09 29.9225 145.914 32.7863C144.78 35.6501 143.183 38.1349 141.125 40.2406C139.108 42.3463 136.671 44.0099 133.815 45.2312C130.958 46.4104 127.828 47 124.425 47ZM124.425 41.3145C126.988 41.3145 129.298 40.8513 131.357 39.9247C133.415 38.9561 135.18 37.6716 136.65 36.0712C138.121 34.4709 139.255 32.5968 140.053 30.4489C140.852 28.259 141.251 25.9427 141.251 23.5C141.251 21.0573 140.852 18.7621 140.053 16.6142C139.255 14.4243 138.121 12.5291 136.65 10.9288C135.18 9.3284 133.415 8.06496 131.357 7.13844C129.298 6.1698 126.988 5.68548 124.425 5.68548C121.862 5.68548 119.551 6.1698 117.493 7.13844C115.434 8.06496 113.67 9.3284 112.199 10.9288C110.729 12.5291 109.594 14.4243 108.796 16.6142C107.998 18.7621 107.599 21.0573 107.599 23.5C107.599 25.9427 107.998 28.259 108.796 30.4489C109.594 32.5968 110.729 34.4709 112.199 36.0712C113.67 37.6716 115.434 38.9561 117.493 39.9247C119.551 40.8513 121.862 41.3145 124.425 41.3145Z" fill={fillStyle || 'black'}/>
<path d="M204.985 10.8024C203.724 9.15995 202.128 7.8965 200.195 7.0121C198.263 6.12769 196.267 5.68548 194.208 5.68548C191.688 5.68548 189.398 6.1698 187.339 7.13844C185.323 8.06496 183.579 9.34946 182.109 10.9919C180.681 12.6344 179.567 14.5717 178.769 16.8038C177.971 18.9937 177.572 21.3522 177.572 23.879C177.572 26.2375 177.95 28.4695 178.706 30.5753C179.462 32.681 180.554 34.534 181.983 36.1344C183.411 37.7348 185.155 38.9982 187.213 39.9247C189.272 40.8513 191.604 41.3145 194.208 41.3145C196.771 41.3145 199.019 40.7881 200.951 39.7352C202.884 38.6824 204.523 37.2083 205.867 35.3132L210.971 39.1667C210.635 39.6299 210.047 40.3038 209.207 41.1882C208.367 42.0305 207.253 42.8938 205.867 43.7782C204.481 44.6205 202.8 45.3575 200.825 45.9893C198.893 46.6631 196.645 47 194.082 47C190.553 47 187.36 46.3262 184.504 44.9785C181.689 43.6308 179.273 41.862 177.257 39.672C175.282 37.4821 173.769 35.0184 172.719 32.2809C171.669 29.5013 171.144 26.7007 171.144 23.879C171.144 20.4256 171.711 17.246 172.845 14.3401C173.98 11.392 175.555 8.86514 177.572 6.75941C179.63 4.61156 182.088 2.94803 184.945 1.76882C187.802 0.589606 190.953 0 194.398 0C197.338 0 200.216 0.568547 203.031 1.70564C205.888 2.84274 208.22 4.5905 210.026 6.94892L204.985 10.8024Z" fill={fillStyle || 'black'}/>
<path d="M235.827 1.1371H241.877V20.4677H242.381L262.106 1.1371H270.551L248.998 21.7944L272 45.8629H263.177L242.381 23.5H241.877V45.8629H235.827V1.1371Z" fill={fillStyle || 'black'}/>
</svg>
)

  return (
    <SvgIcon component={cleanedPaths} inheritViewBox sx={styles} {...otherProps} />
  )
}

export default WordmarkLogo
