// Adapted from https://github.com/Jam3/google-maps-image-api-url/blob/master/index.js
import querystring from 'querystring'

const BASE_URL = 'https://maps.googleapis.com/maps/api/'

type GenerateGoogleMapUrlParams = {
  type: string
  center?: string

  key?: string
  zoom?: number
  size?: string
  scale?: number
  format?: string
  maptype?: 'roadmap' | 'satellite' | 'hybrid' | 'terrain'
  language?: string
  region?: string
  markers?: string
  path?: string
  visible?: string
  style?: string
  location?: string
  pano?: string
  fov?: number
}

const generateGoogleMapUrl = (opts: GenerateGoogleMapUrlParams) => {
  if (opts.type === 'staticmap') {
    if (opts.center === undefined) {
      throw new Error('center must be defined in options')
    } else if (opts.type === undefined) {
      throw new Error('type must be defined in options')
    }
  } else if (opts.type === 'streetview') {
    if (opts.location === undefined && opts.pano === undefined) {
      throw new Error('you must pass in location in options')
    }
  } else if (opts.type === 'streetview/metadata') {
    if (opts.location === undefined) {
      throw new Error('you must pass in location in options')
    }
  }  else {
    throw new Error(
      `${opts.type} is an invalid type. Use "staticmap" or "streetview".`
    )
  }

  return `${BASE_URL + opts.type}?${querystring.stringify(opts)}`
}

export default generateGoogleMapUrl
