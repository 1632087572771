import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

type WebflowHeaderLayoutProps = {
  children: React.ReactNode
}

const WebflowHeaderLayout = (props: WebflowHeaderLayoutProps) => {
  const { children } = props
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      sx={{
        pointerEvents: 'none',
      }}
    >
      <Box
        py={{ xs: '24px', sm: '88px', md: '88px' }}
        boxSizing="border-box"
        width="100%"
        height="100%"
      >
        <Box
          px={{ xs: '35px', sm: '100px', md: '160px' }}
          height="100%"
          maxWidth={{ xs: '304px', sm: '544px', md: '1120px' }}
          margin="auto"
        >
          <Grid container>
            {!isTablet && <Grid item xs={5} />}
            {!isTablet && <Grid item xs={2} />}
            <Grid item xs={12} md={5} width="100%">
              {children}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default WebflowHeaderLayout
