import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby-link'
import { TrackingContextProvider } from '../TrackingContextProvider'
import useTracking, { TrackingConfig } from '../useTracking/useTracking'
import DefaultMultiStepperError, {
  ErrorDisplayProps,
} from './DefaultMultiStepperError'
import DefaultMultiStepperLoader, {
  LoadingDisplayProps,
} from './DefaultMultiStepperLoader'

export type FlowData = {
  [key: string]: any
}

type FunctionMap = {
  [key: string]: Function
}

// All step components in the multistepper should implement this
export type GenericStepComponentProps<Data extends FlowData> = {
  onSubmit: (data: Partial<Data>) => void
  onBack?: () => void
  loading?: boolean
  error?: boolean
}

export type StepConfig<
  Data extends FlowData,
  Functions,
  ComponentProps = {},
  InjectedProps = {}
> = {
  stepName: string // Used to key the step data in flowData
  component: React.FC<
    GenericStepComponentProps<Data> & ComponentProps & InjectedProps
  > // onSubmit, onBack, and loading are passed into the component as props
  componentProps?: ComponentProps
  prerender?: (
    flowData: Partial<Data>,
    functions: Functions
  ) => Promise<Partial<Data>> // Called before a step is rendered
  prerenderLoadingMessage?: string
  injectProps?: (flowData: Partial<Data>) => InjectedProps // Function to inject props from the flow data into the component.
  renderIf?: (flowData: Partial<Data>, functions: Functions) => Promise<boolean> // Only render this step if this function returns true
  onCompletion?: (
    flowData: Partial<Data>,
    functions: Functions
  ) => Promise<Partial<Data>> // Side effect called after this step's component calls onSubmit
  continueAfterComplete?: boolean // If true, the flow will keep moving through steps after onCompletion runs
  onBack?: (result?: Partial<Data>) => void // Function called if back is triggered
  completionLoadingMessage?: string
}

export type MultiStepperProps<Data extends FlowData, Functions> = {
  initialStep?: string // Step name of the first step
  initialData: Partial<Data>
  onUpdated?: (step: number, flowData: Partial<Data>) => void // Called whenever the step or flow data are updated
  stepConfigs: StepConfig<Data, Functions, any, any>[]
  trackingConfig?: TrackingConfig

  errorComponent?: React.FC<ErrorDisplayProps>
  loadingComponent?: React.FC<LoadingDisplayProps>
  useStepComponentLoading?: boolean // Instead of using the loading component, pass the loading prop into the step component.
  useStepComponentError?: boolean // Instead of using the error component, pass the error prop into the step component.

  // Functions made available to all step lifecycle methods.
  functions?: Functions

  useHashRouting?: boolean
}

// T is the type of the flow data
const MultiStepper = <
  Data extends FlowData,
  Functions extends FunctionMap = {}
>(
  props: MultiStepperProps<Data, Functions>
) => {
  const {
    initialStep,
    initialData,
    onUpdated,
    stepConfigs,
    errorComponent,
    loadingComponent,
    functions,
    trackingConfig,
    useStepComponentLoading,
    useStepComponentError,
    useHashRouting,
  } = props
  const [flowData, setFlowData] = useState(initialData)
  const [error, setError] = useState(false)
  const [initialStepComplete, setInitialStepComplete] = useState(false)

  const { track } = useTracking()

  let initialStepIndex = 0
  if (initialStep) {
    initialStepIndex =
      stepConfigs.findIndex(
        (stepConfig) => stepConfig.stepName === initialStep
      ) || 0
  }

  const [step, setStep] = useState(initialStepIndex)
  const [loading, setLoading] = useState(true)
  const [loadingText, setLoadingText] = useState('')

  const currentStepConfig = stepConfigs[step]

  const renderStep = async (
    nextStep: number,
    nextStepConfig: StepConfig<Data, Functions>,
    data: Partial<Data>
  ) => {
    let newData = data
    const { prerender, prerenderLoadingMessage } = nextStepConfig

    if (prerenderLoadingMessage) {
      setLoadingText(prerenderLoadingMessage)
    }
    if (prerender) {
      newData = await prerender(newData, functions || ({} as Functions))
    }
    if (prerenderLoadingMessage) {
      setLoadingText(prerenderLoadingMessage)
    }
    setFlowData(newData)
    setLoading(false)
    setStep(nextStep)
  }

  const renderNextStep = async (_nextStep: number, newData: Partial<Data>) => {
    let nextStep = _nextStep
    while (nextStep < stepConfigs.length) {
      const nextStepConfig = stepConfigs[nextStep]
      const { renderIf } = nextStepConfig
      if (renderIf) {
        const shouldRender = await renderIf(
          newData,
          functions || ({} as Functions)
        )
        if (shouldRender) {
          await renderStep(nextStep, nextStepConfig, newData)
          break
        } else {
          nextStep += 1
        }
      } else {
        await renderStep(nextStep, nextStepConfig, newData)
        break
      }
    }
  }

  const renderFirstStep = async () => {
    try {
      setLoading(true)
      await renderNextStep(initialStepIndex, initialData)
    } catch (e) {
      setError(true)
    }
  }

  useEffect(() => {
    renderFirstStep()
    if (useHashRouting && typeof window !== 'undefined') {
      const onHashChange = () => {
        if (window.location.hash) {
          const stepName = window.location.hash.substring(1)
          const stepIndex = stepConfigs.findIndex(
            (stepConfig) => stepConfig.stepName === stepName
          )
          if (stepIndex >= 0) {
            setStep(stepIndex)
          }
        }
      }

      window.addEventListener('hashchange', onHashChange)
      return () => {
        window.removeEventListener('hashchange', onHashChange)
      }
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (useHashRouting && typeof window !== 'undefined') {
      const stepConfig = stepConfigs[step]
      try {
        navigate(
          `${window.location.pathname}${window.location.search}#${stepConfig.stepName}`
        )
      } catch (e) {
        console.error('navigate is not defined')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  useEffect(() => {
    if (onUpdated) {
      onUpdated(step, flowData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, flowData])

  const onBack = async (result?: Partial<Data>) => {
    try {
      setLoading(true)
      setLoadingText('')

      if (currentStepConfig.onBack) {
        currentStepConfig.onBack()
      }

      let newData = {
        ...flowData,
      }
      if (result) {
        newData = {
          ...flowData,
          [currentStepConfig.stepName]: result,
        }
      }

      let nextStep = step - 1
      while (nextStep >= 0) {
        const nextStepConfig = stepConfigs[nextStep]
        const { renderIf } = nextStepConfig
        if (renderIf) {
          const shouldRender = await renderIf(
            newData,
            functions || ({} as Functions)
          )
          if (shouldRender) {
            await renderStep(nextStep, nextStepConfig, newData)
            break
          } else {
            nextStep -= 1
          }
        } else {
          await renderStep(nextStep, nextStepConfig, newData)
          break
        }
      }
    } catch (e) {
      setError(true)
    }
  }

  const onSubmit = async (result: any) => {
    try {
      // Update flow data with step completion data
      const { onCompletion, completionLoadingMessage } = currentStepConfig
      let newData = {
        ...flowData,
        [currentStepConfig.stepName]: result,
      }

      setInitialStepComplete(true)

      setLoading(true)
      setLoadingText(completionLoadingMessage || '')

      track(currentStepConfig.stepName, {
        [currentStepConfig.stepName]: result,
        actionType: 'button_click',
      })
      // Run any completion side effects for this step
      if (onCompletion) {
        newData = await onCompletion(newData, functions || ({} as Functions))
      }

      // Determine the next step to render
      const nextStep = step + 1
      await renderNextStep(nextStep, newData)
    } catch (e) {
      console.log('error')
      console.log(e)
      setError(true)
    }
  }

  const { component, componentProps } = currentStepConfig
  const StepComponent = component
  let injectedProps = {}
  if (currentStepConfig.injectProps) {
    injectedProps = currentStepConfig.injectProps(flowData)
  }

  const ErrorComponent = errorComponent || DefaultMultiStepperError
  const LoaderComponent = loadingComponent || DefaultMultiStepperLoader

  return (
    <TrackingContextProvider
      name={trackingConfig?.name || 'flow-form'}
      initialTrackingProperties={trackingConfig?.properties}
    >
      {!useStepComponentError && error ? (
        <ErrorComponent text="Oops, an error has occurred. Please try refreshing the page or call / email us for assistance." />
      ) : (
        <>
          {/** If we're using the step component for loading, we want to render the first step with a generic loader first. We use the initialStepComplete state variable to manage this.  */}
          {(!useStepComponentLoading && loading) ||
          (useStepComponentLoading && !initialStepComplete && loading) ? (
            // (useStepComponentLoading && loading) ? (
            <LoaderComponent text={loadingText} />
          ) : (
            <StepComponent
              key={currentStepConfig.stepName}
              loading={loading}
              error={error}
              onBack={onBack}
              onSubmit={onSubmit}
              {...componentProps}
              {...injectedProps}
            />
          )}
        </>
      )}
    </TrackingContextProvider>
  )
}

MultiStepper.defaultProps = {
  initialStep: '',
  trackingConfig: {},
  navigate: () => {
    throw new Error('Navigate not provided!')
  },
}

export default MultiStepper
