/* eslint-disable import/prefer-default-export */

import { Core_UpdateLeadRequestInput as UpdateLeadInput } from '@flock/flock-gql-server/src/__generated__/graphql'
import { normalizeName } from './components/utils'
import {
  FlowData,
  WebflowData,
} from './components/WebflowOnboardingComponents/webflowTypes'
import { getGAClientID } from './utils/analytics'

// remember any changes made here should also be reflected in entities.LeadAnswers
const dataToSend: FlowData = {
  additionalInfo: 'string',
  adjustedValuation: 'number',
  attribution: 'string',
  attributionDetail: 'string',
  brokerReferrerUuid: 'string',
  bathCount: 'float',
  bedCount: 'number',
  halfBathCount: 'number',
  basement: 'string',
  hasPropertyManager: 'boolean',
  hasTenant: 'boolean',
  hasUnit: 'boolean',
  unitNumber: 'string',
  isOwner: 'boolean',
  leaseType: 'string',
  rentAmount: 'money',
  leaseEnd: 'date',
  optionalNotes: 'string',
  hasMortgage: 'string',
  consideringSelling: 'boolean',
  timeline: 'string',
  mortgageAmount: 'money',
  squareFootage: 'number',
  valuation: 'valuation',
  mlsNumber: 'string',
  brokerage: 'string',
  investorAccreditation: 'string',
  isValidMarket: 'boolean',
  dstLead: 'boolean',
  propertyType: 'string',
  ownsWholeBuilding: 'boolean',
  multifamilyInfo: 'string',
  selfReportedValue: 'money',
  prefillSquareFootage: 'number',
  prefillBedCount: 'number',
  prefillBathCount: 'number',
  prefillHalfBathCount: 'number',
  prefillBasement: 'string',
  prefillYearBuilt: 'number',
  prefillBuildingQualityScore: 'number',
  prefillBuildingConditionScore: 'number',
  hasPool: 'boolean',
  hasSolarPanels: 'boolean',
  structuralIssues: 'string',
  upgradedRoof: 'boolean',
  upgradedHVAC: 'boolean',
  upgradedWaterHeater: 'boolean',
  upgradedAppliances: 'boolean',
  defectStructural: 'boolean',
  defectElectrical: 'boolean',
  defectFloodZone: 'boolean',
  defectFireDamage: 'boolean',
  defectSepticWellWater: 'boolean',
  defectUnpermittedAdditions: 'boolean',
  salesAssignee: 'string',
  hasHOA: 'boolean',
  monthlyHOAFee: 'money',
  utmSource: 'string',
  utmCampaign: 'string',
  utmMedium: 'string',
  propertyCondition: 'string',
  gaClientId: 'string',
  hasProceeds: 'string',
  proceedsAmount: 'money',
  proceedsDate: 'date',
  dstHasMortgage: 'text',
  dstMortgageAmount: 'money',
}

type TenantFields = {
  leaseType?: string
  rentAmount?: number
  leaseEnd?: string
}

const processUnitDetails = (flowData: FlowData, prefix: string) => {
  let rentAmount = flowData[`${prefix}RentAmount`]
  if (typeof rentAmount === 'string') {
    rentAmount = parseInt(
      rentAmount.toString().substring(1).replace(/,/g, ''),
      10
    )
  }

  let bathCount = flowData[`${prefix}BathCount`]
  let halfBathCount = 0
  if (bathCount?.toString()?.includes('.5')) {
    bathCount = Math.floor(bathCount)
    halfBathCount = 1
  }

  const hasTenant = flowData[`${prefix}HasTenant`]

  const hasTenantFields: TenantFields = {}
  if (hasTenant === 'true') {
    const leaseType = flowData[`${prefix}LeaseType`]
    hasTenantFields.leaseType = leaseType
    hasTenantFields.rentAmount = rentAmount
    if (leaseType === 'lease') {
      hasTenantFields.leaseEnd = flowData[`${prefix}LeaseEnd`]
    }
  }

  return {
    bedCount: flowData[`${prefix}BedCount`],
    bathCount,
    halfBathCount,
    squareFootage: parseInt(
      flowData[`${prefix}SquareFootage`].toString().replace(/,/g, ''),
      10
    ),
    basement: flowData[`${prefix}Basement`],
    name: flowData[`${prefix}Name`],
    hasTenant,
    ...hasTenantFields,
  }
}

const processAnswers = (flowData: FlowData) => {
  const processedData: any = {}
  let hasUnit1Details = false
  let hasUnit2Details = false
  let hasUnit3Details = false
  let hasUnit4Details = false

  Object.keys(flowData).forEach((key) => {
    const dataType = dataToSend[key]
    if (flowData[key]) {
      switch (dataType) {
        case 'string':
          processedData[key] = flowData[key]
          break
        case 'boolean':
          processedData[key] = flowData[key].toString() === 'true'
          break
        case 'date':
          processedData[key] = flowData[key].toString()
          break
        case 'valuation':
          processedData[key] = parseInt(flowData[key], 10)
          break
        case 'number':
          processedData[key] = parseInt(
            flowData[key].toString().replace(/,/g, ''),
            10
          )
          break
        case 'float':
          processedData[key] = parseFloat(
            flowData[key].toString().replace(/,/g, '')
          )
          break
        case 'money':
          if (typeof flowData[key] === 'number') {
            processedData[key] = flowData[key]
          } else {
            processedData[key] = parseInt(
              flowData[key].substring(1).replace(/,/g, ''),
              10
            )
          }

          break
        default:
          break
      }
      hasUnit1Details = hasUnit1Details || key.includes('unit1')
      hasUnit2Details = hasUnit2Details || key.includes('unit2')
      hasUnit3Details = hasUnit3Details || key.includes('unit3')
      hasUnit4Details = hasUnit4Details || key.includes('unit4')
    }
  })

  const unitDetails = []
  if (hasUnit1Details) {
    unitDetails.push(processUnitDetails(flowData, 'unit1'))
  }
  if (hasUnit2Details) {
    unitDetails.push(processUnitDetails(flowData, 'unit2'))
  }
  if (hasUnit3Details) {
    unitDetails.push(processUnitDetails(flowData, 'unit3'))
  }
  if (hasUnit4Details) {
    unitDetails.push(processUnitDetails(flowData, 'unit4'))
  }

  processedData.unitDetails = unitDetails

  if (processedData.hasMortgage === 'no') {
    delete processedData.mortgageAmount
  }

  if (processedData.hasTenant === false) {
    delete processedData.leaseEnd
    delete processedData.rentAmount
    delete processedData.leaseType
  }

  if (processedData.bathCount?.toString()?.includes('.5')) {
    processedData.bathCount = Math.floor(flowData.bathCount)
    processedData.halfBathCount = 1
  }

  return processedData
}

const flattenObject = (obj: { [key: string]: any }) => {
  const flattened: { [key: string]: any } = {}

  Object.keys(obj).forEach((key) => {
    const value = obj[key]

    if (Object.prototype.toString.call(value) === '[object Date]') {
      flattened[key] = value.toString()
    } else if (
      typeof value === 'object' &&
      value !== null &&
      !Array.isArray(value)
    ) {
      Object.assign(flattened, flattenObject(value))
    } else {
      flattened[key] = value
    }
  })

  return flattened
}

// Used with the GraphQL update lead endpoint
export const flattenUpdateLeadHelper = async (
  flowData: Partial<WebflowData>,
  leadUuid: string,
  updateLeadFn: (params: {
    variables: { updateLeadInput: UpdateLeadInput }
  }) => {}
) => {
  const flattenedData = flattenObject(flowData)
  try {
    const {
      fullName,
      email,
      phoneNumber,
      streetAddress,
      city,
      state,
      zipcode,
      unitNumber,
      salesAssignee,
      brokerUuid,
      lat,
      lng,
      county,
      streetNumber,
      ...answers
    } = flattenedData
    const gaClientID = getGAClientID()
    if (gaClientID) {
      answers.gaClientId = gaClientID
    }

    const preProcessedAnswers = { ...answers }
    if (answers.propertyType === 'duplex') {
      preProcessedAnswers.propertyType = 'multifamily'
      preProcessedAnswers.numUnits = 2
    } else if (answers.propertyType === 'triplex') {
      preProcessedAnswers.propertyType = 'multifamily'
      preProcessedAnswers.numUnits = 3
    } else if (answers.propertyType === 'fourplex') {
      preProcessedAnswers.propertyType = 'multifamily'
      preProcessedAnswers.numUnits = 4
    } else {
      preProcessedAnswers.ownsWholeBuilding = true
    }

    const processedAnswers = processAnswers(preProcessedAnswers)

    await updateLeadFn({
      variables: {
        updateLeadInput: {
          leadUuid,
          fullName: normalizeName(fullName),
          email,
          phoneNumber,
          addressStreet: streetAddress as string,
          addressCity: city as string,
          addressState: state as string,
          addressZip: zipcode as string,
          addressUnit: unitNumber as string,
          addressCounty: county as string,
          addressStreetNumber: streetNumber as string,
          addressLatitude: parseFloat(lat),
          addressLongitude: parseFloat(lng),
          answers: JSON.stringify(processedAnswers),
          source: 'onboarding',
          operatorUuid: salesAssignee,
          brokerUuid,
        },
      },
    })
  } catch (e) {
    console.error('Failed to update lead.')
  }
}
